.fullWidthImage {
  width: 100%;
}
.fluidImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  margin-top: -80px;
}

.community-content {
  min-height: 450px;
}
.js-scroll-trigger {
  cursor: pointer;
}
